@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tw-page-heading {
    @apply tw-mt-page-heading tw-flex tw-gap-0 tw-flex-col tw-min-h-fit;
  }

  .tw-card {
    @apply tw-shadow tw-border-solid tw-border-thin tw-border-gray-500 tw-rounded-xl tw-bg-white tw-py-6 tw-px-12;
  }
}

@layer utilities {
  .tw-border-t-solid {
    border-top-style: solid;
  }
  .tw-border-r-solid {
    border-right-style: solid;
  }
  .tw-border-b-solid {
    border-bottom-style: solid;
  }
  .tw-border-l-solid {
    border-left-style: solid;
  }
  .tw-break-anywhere {
    overflow-wrap: anywhere;
  }
}
